.navbar-space {
    box-sizing: border-box;
    padding-top: 100px;
}

.text-justify {
    text-align: justify;
}

.bg-dark a {
    color: white;
    text-decoration: underline;

}

.bg-dark a:hover {
    text-decoration: none;
    color: white;
}

.link {
    color: black;
}

.link:hover {
    color: black;
    text-decoration: none;
}

.video {
    aspect-ratio: 16 / 9;
    width: 100%;
}

blockquote {
    border-left: 5px solid #dee2e6;
    padding-left: 20px;
    box-sizing: border-box;
    text-align: justify;
    font-style: italic !important;
}

figcaption {
    font-size: 120% !important;
}

figcaption cite {
    font-weight: bold;
    text-transform: uppercase;
    font-style: normal;
}