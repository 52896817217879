.home-image {
  background-image: url("../img/uvod-pozadi.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-uvod {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 70px;
}